var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-link',{staticClass:"boat-product-card-widget bg-white position-relative d-flex flex-column",class:[{'featured': (_vm.featured || _vm.isExhibitorFeatured) && _vm.displayFeaturedBorder}, {'with-btn-map': _vm.mapRoute && _vm.schemaCode}],attrs:{"to":!_vm.urlOpenNewWindow ? _vm.handleBaseUrl : null},on:{"click":function($event){return _vm.onCardClick(_vm.handleBaseUrl)}}},[((_vm.featured || _vm.isExhibitorFeatured))?_c('div',{staticClass:"feature-tag d-flex position-absolute top-0 left-0 right-0 bg-blue-b-3-primary-blue"},[_c('div',{staticClass:"content position-relative w-100 py-1 px-2"},[_c('font-awesome-component',{attrs:{"container-classes":"position-absolute pt-1","icon":"fa-solid fa-award","icon-classes":"icon-40 text-white-white-100"}}),_c('span',{staticClass:"caps caps-3 text-white-white-100 m-0"},[_vm._v(" "+_vm._s(_vm.$t('boat-product-card.feature'))+" ")])],1)]):_vm._e(),_c('div',{staticClass:"d-flex position-relative",class:[
      {'header-sm': _vm.isMobile},
      {'header-md': _vm.isTablet},
      {'header-lg': _vm.isDesktop} ]},[(_vm.certificate || _vm.booth || (_vm._isRecommendedForMe && _vm.displayRecommendedBadge))?_c('div',{staticClass:"d-flex justify-content-between align-items-center position-absolute bottom-0 left-0 right-0 p-2"},[(_vm.booth || (_vm._isRecommendedForMe && _vm.displayRecommendedBadge))?_c('div',{staticClass:"d-flex justify-content-between align-items-center column-gap-1"},[(_vm.booth)?_c('pill-widget',{staticClass:"booth",attrs:{"label":_vm.booth,"size":"sm"}}):_vm._e(),(_vm._isRecommendedForMe && _vm.displayRecommendedBadge)?_c('pill-widget',{staticClass:"for-you-badge",attrs:{"label":_vm.$t('actions.for-you'),"size":"sm","variant":"orange-gradient"}}):_vm._e()],1):_vm._e(),(_vm.certificate)?_c('avatar-solo-widget',{staticClass:"ml-auto",class:(_vm.certificate + "-certification-badge"),attrs:{"with-borders":true,"size":"40","src":"/img/nmma-logo.svg"}}):_vm._e()],1):_vm._e(),_c('button-icon-component',{staticClass:"bookmark w-fit",attrs:{"id":("bookmark-" + _vm.uid),"variant":_vm.bookmarked ? 'alt-2' : 'tertiary',"shape":"round","size":"md"},on:{"on-click":_vm.toggleBookmark},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":_vm.bookmarked
            ? 'fa-solid fa-bookmark'
            : 'fa-regular fa-bookmark',"icon-classes":!_vm.bookmarked ? 'text-neutral-n-8-dark-ink' : '',"container-classes":"icon-24"}})]},proxy:true}])}),(!_vm.empty)?_c('div',{staticClass:"gradient"}):_vm._e(),(!_vm.empty)?_c('img',{staticClass:"image",attrs:{"alt":_vm.name || '',"src":_vm.FileResourceHelper.getFullPath(_vm.image, 'w1000')},on:{"error":function (e) {_vm.empty = true}}}):_c('div',{staticClass:"empty"})],1),_c('div',{staticClass:"boat-product-infos d-flex flex-column justify-content-start align-items-start"},[(_vm.exhibitor && _vm.exhibitor.name)?_c('label',{staticClass:"medium label-4 text-neutral-n-6-label m-0 subtitle"},[_vm._v(" "+_vm._s(_vm.exhibitor.name)+" ")]):_vm._e(),_c('label',{staticClass:"label-card medium text-left text-neutral-n-8-dark-ink m-0 title",class:{more: _vm.empty}},[_vm._v(" "+_vm._s(_vm.name)+" ")]),(_vm.properties && _vm.properties.length > 0)?_c('div',{staticClass:"extra-properties d-flex flex-column row-gap-2 mt-3 mb-4 w-100"},_vm._l((_vm.properties),function(ext,i){return _c('div',{key:i,staticClass:"property d-flex align-items-center justify-content-between column-gap-2"},[_c('label',{staticClass:"label-3 medium m-0 text-left text-neutral-n-6-label"},[_vm._v(" "+_vm._s(ext.name)+" ")]),(ext.type === _vm.ExtraPropertiesType.COLOR
            || ext.type === _vm.ExtraPropertiesType.COLOR_LIST)?_c('color-list-component',{attrs:{"color-list":ext.value}}):_c('label',{staticClass:"label-3 m-0 text-right text-neutral-n-6-label"},[_vm._v(" "+_vm._s(ext.value)+" ")])],1)}),0):_vm._e()]),_c('div',{staticClass:"price d-flex flex-column align-items-center justify-content-center p-2 bg-neutral-n-2-background"},[_c('label',{staticClass:"label-5 medium m-0 text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('boat-product-card.starting'))+" ")]),_c('h5',{staticClass:"m-0 price-tag text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.price ? _vm.priceTag() : 'N/A')+" ")])]),(_vm.mapRoute && _vm.schemaCode)?_c('div',{class:['mt-auto p-3 map-button', {'visibility-hidden': !_vm.mapNavigationUrl}]},[_c('button-component',{attrs:{"rounded":true,"text":("" + (_vm.$t('boat-product-card.show-on-map'))),"size":"md","variant":"secondary"},on:{"on-click":function($event){return _vm.$router.push(_vm.mapNavigationUrl)}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-location-dot"}})]},proxy:true}],null,false,341275896)})],1):_vm._e(),(_vm.authUser)?_c('b-tooltip',{attrs:{"target":("bookmark-" + _vm.uid),"title":_vm.bookmarked
      ? _vm.$t('actions.tooltip.bookmark.remove')
      : _vm.$t('actions.tooltip.bookmark.add'),"boundary":"document","custom-class":"tooltip-style","placement":"top","triggers":"hover"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }